<template>
<div>
  <div class="air__utils__heading"> <h5>Trituraciones</h5> </div>
  <my-breadcrumbs :routes="routes"/>
  <!--FILTROS  -->
  <a-row class="my-4 p-2 bg-white" align="middle" :gutter="[8,24]">
    <!--Planta-->
    <a-col :sm="24" :lg="{span: 12, offset:6}">
      <antGlobalPlantFilter v-model="filterPlant" @change="FetchTable()"/>
    </a-col>
    <!--Turno-->
    <a-col :sm="24" :md="12" :lg="8">
      <ResourceSelect v-model="filterShift"
                      @change="FetchTable()"
                      urlResource="/operationShifts"
                      :filters="shiftParams"
                      placeholder="Seleccionar Turno"
      >
        <span slot-scope="{option}">
          {{ option.name }}
          ({{ moment(option.initial_time).format('HH:mm') }} -
          {{ moment(option.final_time).format('HH:mm') }} )
        </span>
      </ResourceSelect>
    </a-col>
    <!--Formulación-->
    <a-col :sm="24" :md="12" :lg="8">
      <ResourceSelect v-model="filterFormulationType"
                      @change="FetchTable()"
                      urlResource="/formulationTypes"
                      :filters="formulationParams"
                      placeholder="Seleccionar Tipo de Formulación"
      >
        <span slot-scope="{option}">
          {{ option.name}}
        </span>
      </ResourceSelect>
    </a-col>
    <!--Estatus-->
    <a-col :sm="24" :md="12" :lg="8">
      <a-select v-model="filterCrushingStatus" placeholder="Seleccionar Estatus" style="width: 100%" allowClear>
        <a-select-option  v-for="option in crushingStatusesCombo" :key="option" :value="option" >
          {{option}}
        </a-select-option>
      </a-select>
    </a-col>
    <!--Periodo-->
    <a-col :xs="24" :sm="11" :md="11">
      <a-date-picker  v-model="filterFrom" @change="FetchTable()"
                      placeholder="Período inicial" style="width: 100%"
                      format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
    </a-col>
    <a-col :xs="24" :sm="11" :md="11">
      <a-date-picker  v-model="filterTo" @change="FetchTable()"
                      placeholder="Período final" style="width: 100%"
                      format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
    </a-col>
    <a-col :xs="24" :sm="2" :md="2">
      <b-button variant="primary" @click="FetchTable()">
        <b-icon icon="search"></b-icon>
      </b-button>
    </a-col>
  </a-row>
  <!--FILTROS  -->
  <a-row class="mt-2 px-1 py-2 bg-white border border-gray-2">
    <a-tabs v-model="activeTabKey" @change="TabChange"
            class="mx-1" type="card" >
      <a-tab-pane key="1">
        <span slot="tab"><a-icon type="unordered-list"/> Formulaciones</span>
        <!--BOTONERA  -->
        <div class="row justify-content-end mr-2">
          <b-button-group>
            <b-button  variant="primary" pill @click="AddForCrushing()">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Programar Orden de Producción">
                <b-icon icon="plus"/> Agregar trituración
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Programar Orden de Producción">
                <b-icon icon="plus"/>
              </div>
            </b-button>
          </b-button-group>
        </div>
        <!--BOTONERA  -->
        <!--TABLA-->
        <a-table class="mt-4"  :scroll="{x:1300}"
                 :columns="formulationsTable.columns"
                 :data-source="formulationsTable.data"
                 :pagination="formulationsTable.pagination"
                 :loading="formulationsTable.loading"
                 @change="FetchFormulationsTable"
                 :row-selection="{ columnTitle: 'Seleccionar',columnWidth: '10%',
                 selectedRows: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }"
                 :row-key="record => record.id" bordered>
          <template slot="info" slot-scope="data">
            <formulationDescription :formulation="data"/>
          </template>
          <template slot="action" slot-scope="data">
            <b-button :to="{name: 'production_order_scheduling', params: {id: data.id}}"
                      class="bg-real-blue border-real-blue" size="sm" pill>
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
          </template>
        </a-table>
        <!--TABLA-->
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab"><a-icon type="delete"/> Trituraciones</span>
        <a-table class="mt-4 bg-gray-1"
                 :scroll="{x:1300}"
                 :columns="crushingTable.columns"
                 :data-source="crushingTable.data"
                 :pagination="crushingTable.pagination"
                 :loading="crushingTable.loading"
                 @change="FetchCrushingsTable"
                 :row-key="record => record.id" bordered>
          <!-- TABLA Formulacions linked to crushings -->
          <a-table slot="expandedRowRender"
                   class="bg-gray-3"
                   slot-scope="row"
                   :columns="innerCrushingCols"
                   :data-source="row.formulations"
                   :pagination="false"
                   :row-key="record => record.id">
                      <template slot="info" slot-scope="data">
                        <formulationDescription :formulation="data"/>
                      </template>
          </a-table>
          <!-- TABLA Formulacions linked to crushings -->
          <template slot="action" slot-scope="data">
            <b-button :to="{name: 'crushing', params: {type, id: data.id}}"
                      class="bg-real-blue border-real-blue" size="sm" pill>
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </a-row>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import { fractalMixin } from '@/mixins/fractalMixin'
import { crushingStatusesCombo } from '@/constant/crushings'
import { formulationStatuses } from '@/constant/formulations'
import formulationDescription from '../formulations/components/formulationDescription'
export default {
  name: 'mainCrushings',
  mixins: [fractalMixin],
  components: {
    formulationDescription,
  },
  data() {
    return {
      type: this.$route.params.type,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Proceso Operativo',
          name: 'operation_process',
        },
        {
          breadcrumbName: 'Trituración',
          aIcon: '',
        },
      ],
      activeTabKey: '2',
      // constantes
      crushingStatusesCombo: crushingStatusesCombo,
      formulationStatuses: formulationStatuses,
      // Filtros
      formulationParams: {},
      filterShift: undefined,
      filterFormulationType: undefined,
      filterCrushingStatus: undefined,
      filterFrom: '',
      filterTo: '',
      // formulations Table
      formulationsTable: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'info' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            width: '10%',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      // formulations tab
      selectedRowKeys: [],
      // crushings table
      crushingTable: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Folio',
            dataIndex: 'code',
            class: 'text-center',
            // scopedSlots: { customRender: 'crushing' },
          },
          {
            title: 'Estatus',
            dataIndex: 'status',
            class: 'text-center',
            // scopedSlots: { customRender: 'crushing' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            width: '10%',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      innerCrushingCols: [
        {
          title: 'Formulaciones',
          dataIndex: '',
          class: 'text-center',
          scopedSlots: { customRender: 'info' },
        },
      ],
    }
  },
  computed: {
    ...mapState('productionOrders', ['selectedWC']),
    shiftParams() {
      return {
        sort: 'name',
        'filter[workCenters]': this.selectedWC,
      }
    },
    filterPlant: {
      get() {
        return this.selectedWC
      },
      set(value) {
        this.SAVE_WORK_CENTER(value)
      },
    },
  },
  methods: {
    ...mapActions('productionOrders', ['SAVE_WORK_CENTER', 'RESET_WORK_CENTER']),
    // Tab 1 Formulations
    async FetchFormulationsTable(pagEvt) {
      const params = {
        include: 'operationShifts,formulationTypes,processTypes,technicalPrimes,technicalSecondaries,workCenters',
        'filter[status]': this.formulationStatuses.cerrado,
        'filter[tires]': (this.type !== 'solidos') ? 1 : 0,
        'filter[notInCrushings]': '',
      }
      if (this.selectedWC) params['filter[workCenters]'] = this.selectedWC
      if (this.filterShift) params['filter[operationShifts]'] = this.filterShift
      if (this.filterFormulationType) params['filter[formulationTypes]'] = this.filterFormulationType
      if (this.filterFrom && this.filterTo) params['filter[periodBetween]'] = `${this.filterFrom},${this.filterTo}`

      await this.GetTableResource(pagEvt, 'formulations', this.formulationsTable, params)
    },
    // select table formulations
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    AddForCrushing() {
      const me = this
      Modal.confirm({
        title: '¿Desea agregar las formulaciones seleccionadas a un registro de trituración?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          me.formulationsTable.loading = true
          await me.AxiosPost('/attach-formulations-crushing', {
            formulations: me.selectedRowKeys,
            tires: (me.type !== 'solidos') ? 1 : 0,
          }, async() => {
            me.formulationsTable.loading = false
            me.selectedRowKeys = []
            await me.FetchFormulationsTable()
          }, () => {
            me.selectedRowKeys = []
            me.formulationsTable.loading = false
          })
        },
        onCancel () {
        },
      })
    },
    getCheckboxProps(record) {
      return ({
        props: {
          disabled: record.status !== this.formulationStatuses.cerrado, // Column configuration not to be checked
          // disabled: record.status === 'algo', // Column configuration not to be checked
        },
      })
    },
    // Tab 2 Crushings
    async FetchCrushingsTable(pagEvt) {
      const params = {
        include: 'formulations.operationShifts,formulations.formulationTypes,formulations.processTypes,formulations.technicalPrimes,formulations.technicalSecondaries,formulations.workCenters',
        'filter[tires]': (this.type !== 'solidos') ? 1 : 0,
      }
      if (this.selectedWC) params['filter[workCenters]'] = this.selectedWC
      if (this.filterShift) params['filter[operationShifts]'] = this.filterShift
      if (this.filterFormulationType) params['filter[formulationTypes]'] = this.filterFormulationType
      if (this.filterFrom && this.filterTo) params['filter[periodBetween]'] = `${this.filterFrom},${this.filterTo}`

      await this.GetTableResource(pagEvt, 'crushings', this.crushingTable, params)
    },
    // No single tab
    async TabChange(key) {
      if (key === '1') {
        await this.FetchFormulationsTable()
      } else if (key === '2') {
        await this.FetchCrushingsTable()
      }
    },
    async FetchTable() {
      if (this.activeTabKey === '1') {
        await this.FetchFormulationsTable()
      } else if (this.activeTabKey === '2') {
        await this.FetchCrushingsTable()
      }
    },
  },
  async mounted() {
    this.RESET_WORK_CENTER()
    // await this.FetchFormulationsTable()
    await this.FetchCrushingsTable()
  },
}
</script>

<style scoped>

</style>
