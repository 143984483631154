<template>
  <a-descriptions v-if="Object.keys(formulation).length > 0" :column="{ sm: 1, md: 2 }"
                  :title="`ID Orden de Producción/Lote: ${formulation.code}`"
                  bordered size="small">
    <a-descriptions-item label="Turno">
      <strong>{{formulation.operationShifts.code}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Planta">
      <strong>{{formulation.workCenters.name}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Tipo de Formulación">
      <strong>{{formulation.formulationTypes.name}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Tipo de Proceso">
      <strong>{{formulation.processTypes.name}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Inicio">
      <strong>{{moment(formulation.started_at).format('DD/MM/YYYY')}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Fin">
      <strong v-if="formulation.finished_at">{{moment(formulation.finished_at).format('DD/MM/YYYY')}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Hrs.Trabajadas">
      <strong>{{formulation.worked_hours}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Cant.Total Formuladas">
      <strong>{{formulation.total_quantity}}</strong> TON
    </a-descriptions-item>
    <a-descriptions-item label="Total de Contenedores">
      <strong>{{formulation.total_containers}}</strong> PZS
    </a-descriptions-item>
    <a-descriptions-item label="Estatus">
      <strong
              class="bg-secondary text-white p-1 rounded"
              :class="{
                        'bg-primary': formulation.status === formulationStatuses.abierto,
                        'bg-warning': formulation.status === formulationStatuses.cerrado,
               }">
        {{formulation.status}}
      </strong>
    </a-descriptions-item>
    <a-descriptions-item label="Lote Terminado">
      <strong v-if="formulation.batch">{{formulation.batch.code}}</strong>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { formulationStatuses } from '@/constant/formulations'
export default {
  name: 'formulationDescription',
  props: {
    formulation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formulationStatuses,
    }
  },
}
</script>

<style scoped>

</style>
